import React, { useEffect } from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Dashboard from "./Path/Dashboard.jsx";
import UploadListings from "./Path/UploadListings.jsx";
import LoginPage from "./Path/LoginPage.jsx";
import Logo from "./assets/enervie.png";
import Error from "./Path/Error.jsx";
import "./i18n.js";

//import OpenStreetMap from './Components/OpenStreetMap';

const App = () => {
  useEffect(() => {
    const link =
      document.querySelector("link[rel*='icon']") ||
      document.createElement("link");
    link.type = "image/x-icon";
    link.rel = "shortcut icon";
    link.href = Logo;
    document.getElementsByTagName("head")[0].appendChild(link);
  }, []);
  return (
    <BrowserRouter>
      <div>
        <Routes>
          <Route path="/" element={<LoginPage />} />
          <Route path="/Dashboard" element={<Dashboard />} exact />
          <Route path="/UploadListings" element={<UploadListings />} exact />
          <Route path="*" element={<Error />} />
        </Routes>
      </div>
    </BrowserRouter>
  );
};

export default App;
