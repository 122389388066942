import axios from "../api/axiosInstance";
import { getUserId } from "./usersApi";

export async function getAllListings(params) {
  return axios.get(`/listings`, { params });
}

export async function getListingsById(listingsId) {
  return axios.get(`/listings/${listingsId}`);
}

export async function postListingsData(newListingsDataObj) {
  return axios.post(`/listings`, newListingsDataObj);
}

export async function uploadImage(formData) {
  return axios.post(`/users/${getUserId()}/imageUpload`, formData, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
}

export async function updateListingsData(newListingsDataObj, listingsId) {
  return axios.patch(`/listings/${listingsId}`, newListingsDataObj);
}

export async function deleteListing(listingsId) {
  return axios.delete(`/listings/${listingsId}`);
}
