import React, { useState, useRef, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import "./bodyContainer.css";
import SideBar from "../Components/SideBar.jsx";
import { useTranslation } from "react-i18next";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import {
  getListingsById,
  postListingsData,
  updateListingsData,
  uploadImage,
} from "../Services/listingsApi";
import FormData from "form-data";
import Alert from "../Components/Alert.jsx";
import { categoryByName, categoryById } from "../Constants/categories";
import { areasById } from "../Constants/areas";

function UploadListings() {
  const { t } = useTranslation();
  const editor = useRef(null);
  const [listingId, setListingId] = useState(0);
  const [newListing, setNewListing] = useState(true);
  const [updating, setUpdating] = useState(false);
  const [image1, setImage1] = useState(null);
  const [successMessage, setSuccessMessage] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [categoryId, setCategoryId] = useState(0);
  const [areaId, setAreaId] = useState(0);
  const categories = categoryById;
  const areas = areasById;
  const navigate = useNavigate();

  useEffect(() => {
    const token =
      window.localStorage.getItem("accessToken") ||
      window.sessionStorage.getItem("accessToken");
    if (!token) {
      navigate("/");
    }
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
    const _ = async () => {
      if (image1 !== null) {
        const form = new FormData();
        form.append("image", image1);
        const filePath = await uploadImage(form);
        setInput((prevInput) => ({
          ...prevInput,
          media: filePath.data.path,
          removeImage: false,
        }));
      }
    };

    _();
  }, [image1]);

  function handleDragEnter(e) {
    e.preventDefault();
    e.stopPropagation();
  }

  function handleDragLeave(e) {
    e.preventDefault();
    e.stopPropagation();
  }

  function handleDragOver(e) {
    e.preventDefault();
    e.stopPropagation();
  }

  function handleDrop1(e) {
    e.preventDefault();
    e.stopPropagation();
    const file = e.dataTransfer.files[0];
    setImage1(file);
  }

  function handleInputChange1(e) {
    const file = e.target.files[0];
    setImage1(file);
  }

  function handleRemoveImage1() {
    setImage1(null);
    setInput((prevInput) => ({ ...prevInput, media: null, removeImage: true }));
  }

  const [input, setInput] = useState({
    categoryId: 0,
    userId: 1,
    title: "",
    description: "",
    startDate: "",
    endDate: "",
    media: "",
    removeImage: false,
    areaId: 0,
    widget: "",
  });

  const [error, setError] = useState({
    areaId: "",
    categoryId: "",
    title: "",
    description: "",
    startDate: "",
    endDate: "",
    widget: "",
  });

  const handleSubmit = async (event) => {
    let valid = true;
    for (let key in error) {
      var errorMessage = getErrorMessage(key, input[key]);
      var newError = error;
      newError[key] = errorMessage;
      setError(newError);
      if (errorMessage) {
        valid = false;
      }
    }
    if (valid) {
      setUpdating(true);
      event.preventDefault();
      try {
        newListing
          ? await postListingsData(input)
          : await updateListingsData(input, listingId);
        setSuccessMessage(t("listingUpdated"));
        setErrorMessage(false);
        setTimeout(() => {
          setSuccessMessage(false);
          navigate("/Dashboard");
        }, 5000);
      } catch (error) {
        setErrorMessage(t("changesNotSaved"));
        setSuccessMessage(false);
        setTimeout(() => setErrorMessage(false), 5000);
      }
      setUpdating(false);
    } else {
      setErrorMessage(t("invalidData"));
      setSuccessMessage(false);
      setTimeout(() => setErrorMessage(false), 5000);
    }
  };

  useEffect(() => {
    const searchParams = new URLSearchParams(window.location.search);
    var listingId = searchParams.get("listingId");
    setListingId(listingId);
    if (listingId) {
      setNewListing(false);
      getListingsById(listingId).then((listingsResponse) => {
        const listingData = listingsResponse.data.data;
        if (listingData.startDate)
          listingData.startDate = listingData.startDate.slice(0, 10);
        if (listingData.endDate)
          listingData.endDate = listingData.endDate.slice(0, 10);
        setInput(listingData);
        setDescription(listingData.description);
        setCategoryId(listingData.categoryId);
        setAreaId(listingData.area_id);
      });
    }
  }, []);

  const onInputChange = (e) => {
    const { name, value } = e.target;
    setInput((prev) => ({
      ...prev,
      [name]: value,
    }));
    validateInput(e);
  };

  const [description, setDescription] = useState("");

  const onDescriptionChange = (newContent) => {
    setInput((prev) => ({
      ...prev,
      description: newContent.replace(/(<br>|<\/?p>)/gi, ""),
    }));
    setDescription(newContent);
  };

  const getErrorMessage = (name, value) => {
    switch (name) {
      case "title":
        if (!value) {
          return t("pleaseEnterTitle");
        } else {
          return "";
        }

      case "categoryId":
        if (!parseInt(value)) {
          return t("pleaseSelectCategory");
        } else {
          return "";
        }

      case "subCategoryId":
        if (!value && parseInt(input.categoryId) == categoryByName.news) {
          return t("pleaseSelectSubcategory");
        } else {
          return "";
        }

      case "description":
        if (!value) {
          return t("pleaseEnterDescription");
        } else {
          return "";
        }

      case "startDate":
        if (!value && parseInt(input.categoryId) == categoryByName.events) {
          return t("pleaseEnterStartDate");
        } else {
          return "";
        }

      case "endDate":
        if (parseInt(input.categoryId) == categoryByName.events) {
          if (!value) {
            return t("pleaseEnterEndDate");
          } else {
            if (new Date(input.startDate) > new Date(value)) {
              return t("endDateBeforeStartDate");
            } else {
              return "";
            }
          }
        } else {
          return "";
        }
      case "widget":
        if (value && input.widget.split("/")[0] != "https:") {
          return t("pleaseEnterAValidUrl");
        } else {
          return "";
        }

      default:
        return "";
    }
  };

  const validateInput = (e) => {
    let { name, value } = e.target;
    var errorMessage = getErrorMessage(name, value);
    setError((prevState) => {
      return { ...prevState, [name]: errorMessage };
    });
  };

  const handleCategoryChange = (event) => {
    let categoryId = event.target.value;
    setCategoryId(categoryId);
    setInput((prevInput) => ({ ...prevInput, categoryId }));
    validateInput(event);

    const urlParams = new URLSearchParams(window.location.search);
    urlParams.set("categoryId", categoryId);
    const newUrl = `${window.location.pathname}?${urlParams.toString()}`;
    window.history.replaceState({}, "", newUrl);
  };
  const handleAreaChange = (event) => {
    let areaId = event.target.value;
    setAreaId(areaId);
    setInput((prevInput) => ({ ...prevInput, areaId }));
    validateInput(event);
  };

  return (
    <section className="bg-slate-600 body-font relative">
      <SideBar
        handleGetAllListings={() => {}}
        handleGetUserListings={() => {}}
      />

      <div className="container w-auto px-5 py-2 bg-slate-600">
        <div className="bg-white mt-4 p-6 space-y-10">
          <h2 className="text-gray-900 text-lg mb-4 font-medium title-font">
            {t("uploadPost")}
            <div className="my-4 bg-gray-600 h-[1px]"></div>
          </h2>
          <div className="relative mb-4">
            <label
              htmlFor="title"
              className="block text-sm font-medium text-gray-600"
            >
              {t("title")} *
            </label>
            <input
              type="text"
              id="title"
              name="title"
              value={input.title}
              onChange={onInputChange}
              onBlur={validateInput}
              required
              className="overflow-y:scroll w-full bg-white rounded border border-gray-300 focus:border-black focus:ring-2 focus:ring-indigo-200 text-base outline-none text-gray-700 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out shadow-md"
              placeholder={t("enterTitle")}
            />
            <div
              className="h-[24px] text-red-600"
              style={{
                visibility: error.title ? "visible" : "hidden",
              }}
            >
              {error.title}
            </div>
          </div>

          <div className="relative mb-4">
            <label
              htmlFor="dropdown"
              className="block text-sm font-medium text-gray-600"
            >
              {t("category")} *
            </label>
            <select
              type="categoryId"
              id="categoryId"
              name="categoryId"
              value={categoryId}
              onChange={handleCategoryChange}
              required
              disabled={!newListing}
              className="overflow-y:scroll w-full bg-white rounded border border-gray-300 focus:border-black focus:ring-2 focus:ring-indigo-200 text-base outline-none text-gray-700 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out shadow-md disabled:bg-gray-400"
            >
              <option className="font-sans" value={0} key={0}>
                {t("chooseOneCategory")}
              </option>
              {Object.keys(categories).map((key) => {
                return (
                  <option className="font-sans" value={key} key={key}>
                    {t(categories[key])}
                  </option>
                );
              })}
            </select>
            <div
              className="h-[24px] text-red-600"
              style={{
                visibility: error.categoryId ? "visible" : "hidden",
              }}
            >
              {error.categoryId}
            </div>
          </div>

          <div className="relative mb-4">
            <label
              htmlFor="dropdown"
              className="block text-sm font-medium text-gray-600"
            >
              {t("area")}*
            </label>
            <select
              type="areaId"
              id="areaId"
              name="areaId"
              value={areaId}
              onChange={handleAreaChange}
              required
              disabled={!newListing}
              className="overflow-y:scroll w-full bg-white rounded border border-gray-300 focus:border-black focus:ring-2 focus:ring-indigo-200 text-base outline-none text-gray-700 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out shadow-md disabled:bg-gray-400"
            >
              <option className="font-sans" value={0} key={0}>
                {t("chooseOneArea")}
              </option>
              {Object.keys(areas).map((key) => {
                return (
                  <option className="font-sans" value={key} key={key}>
                    {t(areas[key])}
                  </option>
                );
              })}
            </select>
            <div
              className="h-[24px] text-red-600"
              style={{
                visibility: error.categoryId ? "visible" : "hidden",
              }}
            >
              {error.areaId}
            </div>
          </div>
          {categoryId == categoryByName.events && (
            <div className="relative mb-4">
              <div className="items-stretch py-2 grid grid-cols-1 md:grid-cols-2 gap-4">
                <div className="relative">
                  <div className="flex absolute inset-y-0 items-center pl-3 pointer-events-none">
                    <svg
                      aria-hidden="true"
                      className="w-5 h-5 text-gray-600 dark:text-gray-400"
                      fill="currentColor"
                      viewBox="0 0 20 20"
                      xmlns="http://www.w3.org/2000/svg"
                    ></svg>
                  </div>
                  <label
                    htmlFor="startDate"
                    className="block text-sm font-medium text-gray-600"
                  >
                    {t("pleaseEnterStartDate")} *
                  </label>
                  <input
                    type="datetime-local"
                    id="startDate"
                    name="startDate"
                    value={input.startDate}
                    onChange={onInputChange}
                    onBlur={validateInput}
                    className="w-full bg-white rounded border border-gray-300 focus:border-black focus:ring-2 focus:ring-indigo-200 text-base outline-none text-gray-400 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out shadow-md"
                    placeholder={t("pleaseEnterStartDate")}
                  />
                  <div
                    className="h-[24px] text-red-600"
                    style={{
                      visibility: error.startDate ? "visible" : "hidden",
                    }}
                  >
                    {error.startDate}
                  </div>
                </div>

                <div className="relative">
                  <div className="flex absolute inset-y-0 items-center pl-3 pointer-events-none">
                    <svg
                      aria-hidden="true"
                      className="w-5 h-5 text-gray-600 dark:text-gray-400"
                      fill="currentColor"
                      viewBox="0 0 20 20"
                      xmlns="http://www.w3.org/2000/svg"
                    ></svg>
                  </div>
                  <label
                    htmlFor="endDate"
                    className="block text-sm font-medium text-gray-600"
                  >
                    {t("pleaseEnterEndDate")} *
                  </label>
                  <input
                    type="datetime-local"
                    id="endDate"
                    name="endDate"
                    value={input.endDate.replace("T", " ")}
                    onChange={onInputChange}
                    onBlur={validateInput}
                    className="w-full bg-white rounded border border-gray-300 focus:border-black focus:ring-2 focus:ring-indigo-200 text-base outline-none text-gray-400 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out shadow-md"
                    placeholder={t("pleaseEnterEndDate")}
                  />
                  <div
                    className="h-[24px] text-red-600"
                    style={{
                      visibility: error.endDate ? "visible" : "hidden",
                    }}
                  >
                    {error.endDate}
                  </div>
                </div>
              </div>
            </div>
          )}

          <div className="relative mb-4">
            <label
              htmlFor="description"
              className="block text-sm font-medium text-gray-600"
            >
              {t("description")} *
            </label>
            <ReactQuill
              type="text"
              id="description"
              name="description"
              ref={editor}
              value={description}
              onChange={(newContent) => onDescriptionChange(newContent)}
              onBlur={(range, source, editor) => {
                validateInput({
                  target: {
                    name: "description",
                    value: editor.getHTML().replace(/(<br>|<\/?p>)/gi, ""),
                  },
                });
              }}
              placeholder={t("writeSomethingHere")}
              className="w-full bg-white rounded border border-gray-300 focus:border-black focus:ring-2 focus:ring-indigo-200 text-base outline-none text-gray-700 py-0 px-0 leading-8 transition-colors duration-200 ease-in-out shadow-md"
            />
            <div
              className="h-[24px] text-red-600"
              style={{
                visibility: error.description ? "visible" : "hidden",
              }}
            >
              {error.description}
            </div>
          </div>
        </div>
      </div>

      <div className="container w-auto px-5 py-2 bg-slate-600">
        <div className="bg-white mt-4 p-6 space-y-10">
          <h2 className="text-gray-900 text-lg mb-4 font-medium title-font">
            {t("uploadLogo")}
            <div className="my-4 bg-gray-600 h-[1px]"></div>
          </h2>

          <div>
            <label className="block text-sm font-medium text-gray-700">
              {t("addLogoHere")}
            </label>
            <div
              className={`mt-1 flex justify-center rounded-md border-2 border-dashed border-gray-300 px-6 pt-5 pb-6 bg-slate-200`}
              onDrop={handleDrop1}
              onDragOver={handleDragOver}
              onDragEnter={handleDragEnter}
              onDragLeave={handleDragLeave}
            >
              {image1 || input.media ? (
                <div className="flex flex-col items-center">
                  <img
                    className="object-contain h-64 w-full mb-4"
                    src={
                      image1
                        ? URL.createObjectURL(image1)
                        : process.env.REACT_APP_BUCKET_HOST + input.media
                    }
                    alt="uploaded"
                  />
                  <button
                    className="w-full bg-black hover:bg-slate-600 text-white font-bold py-2 px-4 rounded"
                    onClick={handleRemoveImage1}
                  >
                    {t("remove")}
                  </button>
                </div>
              ) : (
                <div className="text-center">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="mx-auto h-12 w-12"
                    viewBox="0 0 20 20"
                    fill="currentColor"
                  >
                    <path
                      fillRule="evenodd"
                      d="M6 2a2 2 0 00-2 2v10a2 2 0 002 2h8a2 2 0 002-2V7.414l-2-2V4a1 1 0 00-1-1H6zm6 5a1 1 0 100-2 1 1 0 000 2z"
                      clipRule="evenodd"
                    />
                  </svg>
                  <p className="mt-1 text-sm text-gray-600">
                    {t("dragAndDropFile")}
                    <label
                      htmlFor="image1-upload"
                      className="font-medium text-indigo-600 hover:text-indigo-500 cursor-pointer"
                    >
                      {t("upload")}
                    </label>{" "}
                    {t("chooseaAFile")}
                  </p>
                  <input
                    id="image1-upload"
                    type="file"
                    className="sr-only"
                    onChange={handleInputChange1}
                  />
                </div>
              )}
            </div>
          </div>
        </div>
      </div>

      <div className="container w-auto px-5 py-2 bg-slate-600">
        <div className="bg-white mt-4 p-6 space-y-10">
          <div className="relative mb-4">
            <label
              htmlFor="widget"
              className="block text-sm font-medium text-gray-600"
            >
              {t("widget")}
            </label>
            <input
              type="text"
              id="widget"
              name="widget"
              value={input.widget}
              onChange={onInputChange}
              onBlur={validateInput}
              className="overflow-y:scroll w-full bg-white rounded border border-gray-300 focus:border-black focus:ring-2 focus:ring-indigo-200 text-base outline-none text-gray-700 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out shadow-md"
              placeholder={t("enterWidget")}
            />
            <div
              className="h-[24px] text-red-600"
              style={{
                visibility: error.widget ? "visible" : "hidden",
              }}
            >
              {error.widget}
            </div>
          </div>
        </div>
      </div>

      <div className="container w-auto px-5 py-2 bg-slate-600">
        <div className="bg-white mt-4 p-6 space-y-10">
          <div className="relative mb-4 mt-8 border-white">
            <button
              type="button"
              onClick={handleSubmit}
              disabled={updating}
              className="w-full bg-black hover:bg-slate-600 text-white font-bold py-2 px-4 rounded disabled:opacity-60"
            >
              {t("saveChanges")}
              {updating && (
                <svg
                  aria-hidden="true"
                  className="inline w-5 h-5 ml-2 text-gray-200 animate-spin dark:text-gray-600 fill-gray-600 dark:fill-gray-300"
                  viewBox="0 0 100 101"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                    fill="currentColor"
                  />
                  <path
                    d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                    fill="currentFill"
                  />
                </svg>
              )}
            </button>
          </div>
          <div>
            {successMessage && (
              <Alert type={"success"} message={successMessage} />
            )}
            {errorMessage && <Alert type={"danger"} message={errorMessage} />}
          </div>
        </div>
      </div>
    </section>
  );
}

export default UploadListings;
