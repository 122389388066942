import React, { useState, useEffect } from "react";

function Alert({ type, message }) {
  const [tailwindClass, setTailwindClass] = useState({});
  useEffect(() => {
    if (type == "danger") {
      setTailwindClass(
        "w-full bg-red-200 border text-red-700 px-6 py-3 rounded"
      );
    } else if (type == "success") {
      setTailwindClass(
        "w-full bg-green-200 border text-green-700 px-6 py-3 rounded"
      );
    } else {
      setTailwindClass(
        "w-full bg-blue-100 border text-blue-700 px-6 py-3 rounded"
      );
    }
  });

  return <div className={tailwindClass}>{message}</div>;
}

export default Alert;
