export const areasByName = {
  Smart_Industry: 1,
  Smart_City: 2,
  Wohnungswirtschaft: 3,
  Smart_Grid: 4,
};
export const areasById = {
  1: "Smart Industry",
  2: "Smart City",
  3: "Wohnungswirtschaft",
  4: "Smart Grid",
};
