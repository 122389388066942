const sortLatestFirst = (arr) => {
  arr.sort(compareDateTime);
  return arr.reverse();
};

const compareDateTime = (a, b) => {
  const dateA = new Date(a.createdAt);
  const dateB = new Date(b.createdAt);

  if (dateA < dateB) {
    return -1;
  }
  if (dateA > dateB) {
    return 1;
  }
  return 0;
};

module.exports = {
  sortLatestFirst,
};
