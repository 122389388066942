import React, { useState, useEffect } from "react";
import SideBar from "../Components/SideBar.jsx";
import { deleteListing, getAllListings } from "../Services/listingsApi";
import { useNavigate } from "react-router-dom";
import { sortLatestFirst } from "../Services/helper";
import { categoryById } from "../Constants/categories";
import { useTranslation } from "react-i18next";
import LISTINGSIMAGE from "../assets/ListingsImage.png";
const Dashboard = () => {
  window.scrollTo(0, 0);
  const { t } = useTranslation();
  const [listings, setListings] = useState([]);
  const [pageNo, setPageNo] = useState(1);
  let navigate = useNavigate();
  const navigateTo = (path) => {
    if (path) {
      navigate(path);
    }
  };
  useEffect(() => {
    const token =
      window.localStorage.getItem("accessToken") ||
      window.sessionStorage.getItem("accessToken");
    if (token) {
      fetchListings();
      document.title = "Dashboard";
    } else {
      navigateTo("/");
    }
  }, []);

  useEffect(() => {
    fetchListings();
  }, [pageNo]);

  function fetchListings() {
    getAllListings({ pageNo }).then((response) => {
      if (response.data.data.length > 0) {
        setListings([...sortLatestFirst(response.data.data)]);
      } else {
        setPageNo(pageNo - 1);
      }
    });
  }

  //Navigate to Edit Listings page Starts
  function goToEditListingsPage(listing) {
    navigateTo(
      `/UploadListings?listingId=${listing.id}&cityId=${listing.cityId}`
    );
  }

  const [showConfirmationModal, setShowConfirmationModal] = useState({
    visible: false,
    listing: null,
    onConfirm: () => {},
    onCancel: () => {},
  });

  function handleDelete(listing) {
    deleteListing(listing.id)
      .then((res) => {
        if (res.status == 200) {
          setListings(
            listings.filter(
              (l) => l.cityId !== listing.cityId || l.id !== listing.id
            )
          );
        }
        setShowConfirmationModal({ visible: false }); // hide the confirmation modal
      })
      .catch((error) => console.log(error));
  }

  function deleteListingOnClick(listing) {
    setShowConfirmationModal({
      visible: true,
      listing,
      onConfirm: () => handleDelete(listing),
      onCancel: () => setShowConfirmationModal({ visible: false }),
    });
  }

  //Navigate to Edit Listings page Starts

  return (
    <section className="bg-slate-600 body-font relative">
      <SideBar />

      {/* <html className="h-full bg-gray-100" />
      <body className="h-full" /> */}

      <div className="container w-auto px-0 lg:px-5 py-2 bg-slate-600 h-screen">
        <div className="bg-white mt-10 p-0 space-y-10 overflow-x-auto">
          <table className="w-full text-sm text-left lg:mt-[2rem] mt-[2rem] text-gray-500 dark:text-gray-400 p-6 space-y-10 rounded-lg">
            <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-50 dark:text-gray-700">
              <tr>
                <th scope="col" className="px-6 sm:px-6 py-3">
                  {t("listings")}
                </th>
                <th
                  scope="col"
                  className="px-6 sm:px-3 py-3 hidden lg:table-cell"
                >
                  {t("category")}
                </th>
                <th scope="col" className="px-6 py-3 hidden lg:table-cell">
                  {t("date_of_creation")}
                </th>
                <th scope="col" className="px-6 py-3">
                  {t("action")}
                </th>
              </tr>
            </thead>
            <tbody>
              {listings.map((listing) => {
                return (
                  <tr
                    key={listing.id}
                    className="bg-white border-b dark:bg-white dark:border-white hover:bg-gray-50 dark:hover:bg-gray-50"
                  >
                    <th
                      scope="row"
                      className="flex items-center px-6 py-4 text-gray-900 whitespace-nowrap dark:text-white cursor-pointer"
                    >
                      <img
                        className="w-10 h-10 rounded-full hidden sm:table-cell"
                        src={
                          listing.media
                            ? process.env.REACT_APP_BUCKET_HOST + listing.media
                            : LISTINGSIMAGE
                        }
                        alt="avatar"
                      />
                      <div className="pl-0 sm:pl-3">
                        <div className="font-normal text-gray-500">
                          {listing.title}
                        </div>
                      </div>
                    </th>
                    <td className="px-6 py-4 hidden lg:table-cell">
                      {t(categoryById[listing.categoryId])}
                    </td>
                    <td className="px-6 py-4 hidden lg:table-cell">
                      {new Date(listing.createdAt).toLocaleString("de")}
                    </td>
                    <td className="px-6 py-4">
                      <button
                        className="font-medium text-blue-600 dark:text-blue-500 hover:underline cursor-pointer pr-2"
                        onClick={() => goToEditListingsPage(listing)}
                      >
                        {t("edit")}
                      </button>
                      <button
                        className="font-medium text-blue-600 dark:text-blue-500 hover:underline cursor-pointer"
                        onClick={() => deleteListingOnClick(listing)}
                      >
                        {t("delete")}
                      </button>
                      {showConfirmationModal.visible && (
                        <div className="fixed z-10 inset-0 overflow-y-auto">
                          <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
                            <div
                              className="fixed inset-0 transition-opacity"
                              aria-hidden="true"
                            >
                              <div className="absolute inset-0 bg-gray-500 opacity-75"></div>
                            </div>
                            <span
                              className="hidden sm:inline-block sm:align-middle sm:h-screen"
                              aria-hidden="true"
                            >
                              &#8203;
                            </span>
                            <div className="inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full">
                              <div className="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
                                <div className="sm:flex sm:items-start">
                                  <div className="mx-auto flex-shrink-0 flex items-center justify-center h-12 w-12 rounded-full bg-red-100 sm:mx-0 sm:h-10 sm:w-10">
                                    <svg
                                      className="h-6 w-6 text-red-600"
                                      xmlns="http://www.w3.org/2000/svg"
                                      fill="none"
                                      viewBox="0 0 24 24"
                                      stroke="currentColor"
                                      aria-hidden="true"
                                    >
                                      <path
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                        strokeWidth="2"
                                        d="M6 18L18 6M6 6l12 12"
                                      />
                                    </svg>
                                  </div>
                                  <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
                                    <h3 className="text-lg leading-6 font-medium text-gray-900">
                                      {t("areYouSure?")}
                                    </h3>
                                    <div className="mt-2">
                                      <p className="text-sm text-gray-500">
                                        {t("deleteWarning")}
                                      </p>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div className="bg-gray-50 px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse">
                                <button
                                  onClick={showConfirmationModal.onConfirm}
                                  type="button"
                                  className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-red-600 text-base font-medium text-white hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500 sm:ml-3 sm:w-auto sm:text-sm"
                                >
                                  {t("delete")}
                                </button>

                                <button
                                  onClick={showConfirmationModal.onCancel}
                                  type="button"
                                  className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:w-auto sm:text-sm"
                                >
                                  {t("cancel")}
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      )}
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
        <div className="bottom-5 right-5 mt-5 px-1 py-2 text-xs font-medium text-center text-white bg-black rounded-lg hover:bg-gray-800 focus:ring-4 focus:outline-none focus:ring-gray-300 dark:bg-gray-600 dark:hover:bg-gray-700 dark:focus:ring-gray-800 float-right cursor-pointer">
          {pageNo != 1 ? (
            <button onClick={() => setPageNo(pageNo - 1)}>
              <span className="text-md px-3 hover:bg-gray-800 cursor-pointer rounded-lg">
                {"<"}{" "}
              </span>
            </button>
          ) : (
            <span />
          )}
          <span className="text-lg px-3">
            {t("page")} {pageNo}
          </span>
          <button onClick={() => setPageNo(pageNo + 1)}>
            <span className="text-lg px-3 hover:bg-gray-800 cursor-pointer rounded-lg">
              {">"}
            </span>
          </button>
        </div>
      </div>
    </section>
  );
};

export default Dashboard;
